import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import './css/Gender.css';

class Gender extends React.Component {
    state = {
        isMale: false,
        isFemale: false
    }
    setIsMale = () => {
        this.setState({
            isMale: true,
            isFemale: false
        })
        this.props.action('MALE');
    }
    setIsFemale = () => {
        this.setState({
            isMale: false,
            isFemale: true
        })
        this.props.action('FEMALE');
    }
    render() {
      return  <InputGroup className="d-flex justify-content-between gender">
                <InputGroup.Prepend>
                  <InputGroup.Text className="gender-element-text">MALE</InputGroup.Text>
                  <InputGroup.Checkbox
                    checked={this.state.isMale}
                    onChange={this.setIsMale} 
                  />
                </InputGroup.Prepend>
                <InputGroup.Prepend>
                <InputGroup.Text className="gender-element-text">FEMALE</InputGroup.Text>
                  <InputGroup.Checkbox
                    checked={this.state.isFemale}
                    onChange={this.setIsFemale}
                  />
                </InputGroup.Prepend>
              </InputGroup>;
    }
}

export default Gender;