import React from 'react';

import Email from './Email';
import Telephone from './Telephone';
import Name from './Name';
import RegisterButton from './RegisterButton'
import Logo from './Logo'
import Gender from './Gender';
import api from '../service/apiService';
import Notifications from './Notifications';
import Thanks from './Thanks';

class Form extends React.Component {
  state = {
    name: '',
    telephone: '',
    gender: '',
    email: '',
    notification: {},
    displayModal: false
  }

  render() {
    return <form>
            <Logo />
            <Name action={this.setName} />
            <Gender action={this.setGender} />
            <Telephone action={this.setTelephone} />
            <Email action={this.setEmail}/>
            <Notifications action={this.setNotifications} />
            <RegisterButton action={this.submitForm}/>
            <Thanks show={this.state.displayModal} closeModal={this.closeModal}/>
          </form>
  }
  
  setName = (nameValue) => {
    this.setState({
      name: nameValue.trim()
    })
  }
  setGender = (genderValue) => {
    this.setState({
      gender: genderValue
    })
  }
  setTelephone = (telephoneValue) => {
    this.setState({
      telephone: telephoneValue
    })
  }
  setEmail = (emailValue) => {
    this.setState({
      email: emailValue
    })
  }
  setNotifications = (notificationValues) => {
    this.setState({
      notification: notificationValues
    })
  }
  submitForm = () => {
    let payload = {
      name: this.state.name,
      telephone: this.state.telephone,
      email: this.state.email,
      gender: this.state.gender,
      notification: this.state.notification
    };
    api.subscribe(payload)
    this.setState({
      displayModal: true
    })
  }
  closeModal = () => {
    this.setState({
      displayModal: false
    })
  }
}

export default Form;
