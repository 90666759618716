import API from '../config/api';
import constant from '../config/constant'

const subscribe = (payload) => {
    API.post(constant.SUBSCRIBE_URL, payload)
}

const apiService = { 
    subscribe
};

export default apiService;