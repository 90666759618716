import React from 'react';
import Image from 'react-bootstrap/Image';

class Logo extends React.Component {
    render() {
      return <Image 
                src={require('../assets/img/logo.jpg')}
                roundedCircle
                style={{
                    width: '150px',
                    padding: '20px'
                }}
              />
    }
}

export default Logo;
