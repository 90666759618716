import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import './css/Name.css'

class Name extends React.Component {
  state = {
    firstName: '',
    lastName: ''
  }
  setFirstName = (event) => {
    this.setState({
      firstName: event.target.value
    })
    this.props.action(event.target.value + ' ' + this.state.lastName)
  }
  setLastName = (event) => {
    this.setState({
      lastName: event.target.value
    })
    this.props.action(this.state.firstName + ' ' + event.target.value)
  }
  render() {
    return  <InputGroup>
              <InputGroup.Prepend className="name-element">
                <InputGroup.Text className="name-text-element justify-content-center">Personal Information</InputGroup.Text>
              </InputGroup.Prepend>
              <InputGroup.Prepend className="name-checkboxs">
              <input 
                className="form-control"
                type='text'
                placeholder="First Name"
                onChange={ this.setFirstName.bind(this) }
              />
              <input 
                className="form-control"
                type='text'
                placeholder="Last Name"
                onChange={ this.setLastName.bind(this) }
              />
              </InputGroup.Prepend>
            </InputGroup>;
    }
}

export default Name;