import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';

class Email extends React.Component {
  state = {
    username: '',
    domain: ''
  }
  setUsername = (event) => {
    this.setState({
      username: event.target.value
    })
    this.props.action(event.target.value + '@' + this.state.domain)
  }
  setDomain = (event) => {
    this.setState({
      domain: event.target.value
    })
    this.props.action(this.state.username + '@' + event.target.value)
  }
  render() {
    return  <InputGroup className="mb-3">
              <input 
                className="form-control"
                type='text'
                placeholder="username"
                onChange={ this.setUsername.bind(this) }
              />
              <InputGroup.Prepend>
                <InputGroup.Text>@</InputGroup.Text>
              </InputGroup.Prepend>
              <input 
                className="form-control"
                type='text'
                placeholder="domain"
                onChange={ this.setDomain.bind(this) }                  
              />
            </InputGroup>
    }
}

export default Email;
