import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import './css/Notifications.css';

class Notifications extends React.Component {
    state = {
        sms: false,
        email: false,
        viber: false,
        whatsApp: false
    }
    setSms = () => {
        this.setState({
            sms: !this.state.sms
        },
        this.setFormState
        )
        
    }
    setEmail = () => {
        this.setState({
            email: !this.state.email
        },
        this.setFormState
        )
    }
    setViber = () => {
        this.setState({
            viber: !this.state.viber
        },
        this.setFormState
        )
    }
    setWhatsApp = () => {
        this.setState({
            whatsApp: !this.state.whatsApp
        },
        this.setFormState
        )
    }
    setFormState = () => {
        this.props.action(this.state);
    }
    render() {
      return  <InputGroup className="d-flex justify-content-left notifications">
              <InputGroup.Prepend>
                <InputGroup.Text className="justify-content-center notification-title">I want to recieve newsletters via</InputGroup.Text>
              </InputGroup.Prepend>
                <InputGroup.Prepend>
                  <InputGroup.Text className="notification-element-text">SMS</InputGroup.Text>
                  <InputGroup.Checkbox 
                    checked={this.state.sms}
                    onChange={this.setSms} 
                  />
                </InputGroup.Prepend>
                <InputGroup.Prepend>
                <InputGroup.Text className="notification-element-text">EMAIL</InputGroup.Text>
                  <InputGroup.Checkbox
                    checked={this.state.email}
                    onChange={this.setEmail}
                  />
                </InputGroup.Prepend>
                <InputGroup.Prepend>
                <InputGroup.Text className="notification-element-text">VIBER</InputGroup.Text>
                  <InputGroup.Checkbox
                    checked={this.state.viber}
                    onChange={this.setViber}
                  />
                </InputGroup.Prepend>
                <InputGroup.Prepend>
                <InputGroup.Text className="notification-element-text">WHATSAPP</InputGroup.Text>
                  <InputGroup.Checkbox
                    checked={this.state.whatsApp}
                    onChange={this.setWhatsApp}
                  />
                </InputGroup.Prepend>
              </InputGroup>;
    }
}

export default Notifications;