import React from 'react';
import Modal from 'react-bootstrap/Modal';

class Thanks extends React.Component {
    close = () => {
        this.props.closeModal()
    }
    render() {
      return <Modal
                size="sm"
                show={this.props.show}
                onHide={this.close}
                 aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                    95FOUR
                    </Modal.Title>
                </Modal.Header>
            <Modal.Body>Thanks for the subscription</Modal.Body>
    </Modal>
    }
}

export default Thanks;
