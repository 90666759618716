import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import FormControl from 'react-bootstrap/FormControl';

class Telephone extends React.Component {
    state = {
        operators: [
            '070',
            '071',
            '072',
            '073',
            '074',
            '075',
            '076',
            '077',
            '078',
            '079'
        ],
        operator: 'Operator',
        telNumber: ''    
    }
    _loadOperatorItems = () => {
      return this.state.operators.map(op => {
        return <Dropdown.Item 
            key={op} 
            onClick={(v) => {
              this.setState({
                operator: v.target.text
              })
            }}
            >
            {op}
            </Dropdown.Item>
      })
    }
    setTelNumber = (event) => {
      this.setState({
        telNumber: event.target.value
      })
      this.props.action(this.state.operator + ' ' + event.target.value)
    }
    render() {
      return  <InputGroup className="mb-3">
                <DropdownButton
                  as={InputGroup.Prepend}
                  variant="outline-secondary"
                  title={this.state.operator}
                  id="input-group-dropdown-1"
                >
                { this._loadOperatorItems() }
                </DropdownButton>
                <FormControl
                  placeholder="Telephone Number"
                  aria-describedby="basic-addon1"
                  value={this.state.telNumber}
                  onChange={(event) => this.setTelNumber(event)} />
              </InputGroup>
    }
}

export default Telephone;
