import React from 'react';
import Button from 'react-bootstrap/Button';
import './css/RegisterButton.css'

class RegisterButton extends React.Component {
    render() {
      return <div>
            <Button 
                className="subscribe-button"
                variant="primary" 
                size="lg" 
                block
                onClick={this.props.action}
                >
                SUBSCRIBE
            </Button>
            </div>
    }
}

export default RegisterButton;